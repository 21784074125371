import React, {Component} from 'react';
import "./OfflineScreen.css";

type OfflineScreenProps = {
	// using `interface` is also ok
};

export default class OfflineScreen extends Component<OfflineScreenProps> {

	// @ts-ignore
	constructor(props) {
		super(props);
		document.title = "asdfmovie mash";
		this.state = {}
	}


	render() {
		return (

			<div className="loadWrapper">
				<div className="container d-flex h-100 flex-column">
					<div className="flex-grow-1"></div>
					<div className="row justify-content-center">
						<h2 className="rateScreenTitle">it's over! thanks for the tasty data</h2>
					</div>
					<div className="flex-grow-1"></div>
				</div>
			</div>
		)
	}

}
