import React, {Component} from 'react';
import './App.css';
import Routes from "./Routes";
import {initialize, pageview} from "react-ga";

type AppProps = {};


class App extends Component<AppProps, any> {

	render() {

		let currentYear = new Date().getFullYear();
		initialize('UA-173650014-1');
		pageview(window.location.pathname + window.location.search);
		return (
			<>
				{window.location.href.includes("dev") || window.location.href.includes("test") || window.location.href.includes("localhost") ? (
					<div className="row">
						<div className="col-12 banner">BETA VERSION - This version of the site is not ready for release
							- please do not share to the public
						</div>
					</div>
				) : (<></>)}
				<Routes/>
				<div className="container wrapper">
					<div className="copyright">All art, titles and transcripts copyright &copy; <a
						href="https://twitter.com/thetomska" target="_blank" rel="noopener noreferrer">Thomas
						Ridgewell</a> 2006-{currentYear}
					</div>
				</div>
			</>
		);
	}

}

export default App;
