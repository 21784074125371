// @ts-ignore
import React from "react";
import {Route, Switch} from "react-router-dom";
import RateScreen from "./containers/RateScreen";
import OfflineScreen from "./containers/OfflineScreen/OfflineScreen";

// @ts-ignore
export default () => (
	<Switch>
		{/*<Route component={RateScreen}/>*/}
		<Route component={OfflineScreen}/>
	</Switch>
);
