import Amplify from 'aws-amplify';

export default Amplify.configure({
	API: {
		endpoints: [
			{
				name: "asdf-api",
				endpoint: "api",
			}
		]
	}
});
